import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import WufooForm from "react-wufoo-embed"

import Layout from "../layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Make-A-Deal - The first Dealer CRM that ensures every Up is logged. Sales staff love it!" keywords={[`gatsby`, `application`, `react`]} />
    <Hero />
    <div className="grid-container" style={{marginTop: "230px", height: "300px"}}>
      <div className="grid-x grid-margin-x">
        <div className="cell medium-5 medium-offset-1">
          <h3>Get control of your Dealership<br />... and keep control!</h3>
          <p>Your sales team hates logging leads. Are you capturing all the leads that walk on your lot in your CRM? Use Make-A-Deal to ensure that every prospect that walks on your lot is accounted for.</p>
        </div>
        <div className="cell medium-6 text-center"><Img fluid={data.keepImg.childImageSharp.fluid} /></div>
      </div>
    </div>
    <div style={{height: "450px", position: "relative"}} className="grid-x align-middle">
      <div className="grid-container" style={{position: "relative", zIndex: 100}}>
        <div className="grid-x">
          <div className="cell medium-8 medium-offset-2 text-center align-middle">
            <h3 style={{fontSize: "1.6rem", lineHeight: '2.6rem', color: "#1EA0F1"}}>Use our team to log your floor traffic freeing up your salespeople to do what they do best. SELL! SELL! SELL!</h3>
            <p>Make-A-Deal takes back the secretary work from your salespeople. Most salespeople loathe entering data into a computer system. Our system removes that hindrance. We log the leads for you.</p>
          </div>
        </div>      
      </div>
      <Img
        fluid={data.istock.childImageSharp.fluid}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%"
        }}
      />
    </div>
    <div style={{marginTop: "100px", marginBottom: "100px"}}>
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell medium-6">
          <Img fluid={data.appImage.childImageSharp.fluid} />
        </div>
        <div className="cell medium-4">
        <h3 style={{textTransform: "uppercase", fontSize: "1rem", color: "#91A5B3", fontWeight: 400}}>Up System</h3>
        <h2>The most accountable system on the market</h2>
        <p>Every up is accounted for that walks on to the lot. Every up is logged into the Make-A-Deal database. Managers are flagged when the sales team diverges from the process.</p>
        </div>
      </div>
    </div>
    <div style={{marginTop: "100px", marginBottom: "100px"}}>
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell medium-4 medium-offset-2">
          <h3 style={{textTransform: "uppercase", fontSize: "1rem", color: "#91A5B3", fontWeight: 400}}>Logging Ups | Follow-up</h3>
          <h2>Simplicity for even the least technical</h2>
          <p>Sales guys suck at complicated computer programs. Make-A-Deal is so simple that your 70-year old sales veteran can use the system without even touching a computer. Make-A-Deal does not hinder the technology challenged, it <strong>empowers
            the technology savvy.</strong></p>
          <strong>A system that Sales veterans can use like their old box of Ups!</strong>
        </div>
        <div className="cell medium-6">
          <Img fluid={data.appImage.childImageSharp.fluid} />
        </div>
      </div>
    </div>
    <div style={{backgroundColor: "#26ABF3", paddingTop: 80, paddingBottom: 80, color: "white"}}>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
        <div className="cell medium-12 text-center"><h2>What is measured is managed, what managed improves</h2></div>
          <div className="cell medium-4 medium-offset-1">
          <Img fluid={data.analytics.childImageSharp.fluid} />
          </div>
          <div className="cell medium-6 medium-offset-1">
            <ul>
              <li>Manage your sales team with extensive analytics</li>
              <li>See everything a salesperson has sone with prospects and where they are lacking</li>
              <li>Easily manage late follow-up and get caught up</li>
              <li>Automatically grade your sales team on how well the do their job</li>
              <li>and much much more...</li>
            </ul>
            <p>Make-A-Deal flags unacceptable behavior and provides Managers with analytics to coach salespeople to be awesome!</p>
          </div>
        </div>
      </div>
    </div>
    <div className="grid-container" style={{marginTop: "85px"}}>
      <div className="grid-x grid-margin-x">
        <div className="cell medium-12 text-center">
          <h2 style={{fontSize: "2.6rem"}}>Ready to schedule a demo?</h2>
        </div>
        <div className="cell medium-6 medium-offset-3">
          <WufooForm userName="activelamp" formHash="m12w74se1ymqqcv" />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    topImg: file(relativePath: { eq: "mad-top-illustration.svg" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }

    keepImg: file(relativePath: { eq: "mad-keep-illustration.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    istock: file(relativePath: { eq: "istock-155372447.png" }) {
      childImageSharp {
        fluid(maxHeight: 340) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    appImage: file(relativePath: { eq: "mad-up-system-01.png" }) {
      childImageSharp {
        fluid(maxHeight: 340) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    analytics: file(relativePath: { eq: "mad-analytics-illustration-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
  }
`
