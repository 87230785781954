import React from 'react'

import madLogo from '../../images/logos/mad-logo-text.svg'
import madIllustration from '../../images/mad-top-illustration.svg'

const Hero = () => 
    <div style={{
        backgroundImage: "linear-gradient(#03ADE1, #1EA0F1)",
        color: "#fff"
    }} className="text-center">
        <div className="grid-container">
            <div className="grid-x grid-margin-x">
                <div className="cell medium-8 medium-offset-2" style={{ paddingTop: "3rem"}}>
                    <img src={ madLogo } alt="Make-A-Deal Logo" />
                    <h1 style={{
                        fontSize: "3rem",
                        marginTop: 0
                    }}>Manage relationships without frustrating your salespeople</h1>
                    <h2 style={{
                        fontWeight: 200,
                        fontSize: '1.6rem',
                        lineHeight: '2.2rem'
                    }}
                    >
                    Generate more sales with <strong>the first Dealer CRM that ensures every Up is logged</strong>. We take the frustrations out of using a CRM.
                    </h2>
                    <a className="button large alert">Schedule a Demo</a>
                </div>
            </div>
            
            <img src={ madIllustration } alt="Make-A-Deal Logo" style={{
                marginBottom: "-185px",
                marginTop: '30px'
            }} />
        </div>
        
    </div>

export default Hero
